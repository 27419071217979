#side-bar::-webkit-scrollbar,
#main-nav::-webkit-scrollbar {
  width: 0 !important;
}

#side-bar,
#main-nav {
  -ms-overflow-style: none;
}

body {
  overflow-x: hidden;
  background-color: #fff !important;
  -webkit-text-size-adjust: none;
}
::selection {
  background-color: #dce2ff;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes chart-horizontal-animation {
  0% {
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}
.animated-background {
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}
a {
  color: #64008f;
}
fieldset {
  margin-top: 0 !important;
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.page-transition-enter {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}
.page-transition-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 400ms, transform 400ms;
}
.page-transition-exit {
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}
